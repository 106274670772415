import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Personalizza from "../../components/personalizza";
import SalvaFreschezza from "../../components/salvafreschezza";
import Hero from "../../utils/hero";
import Link from "../../utils/link";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";

const SalviettePage = () => {
  const schema = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "Wipes",
      image: "",
      description:
        "Neutral packaging with attention to detail, suitable for all kinds of situations and activities. We also make custom wipes to give an original touch to your venue.",
      brand: {
        "@type": "Brand",
        name: "Mettifogo",
      },
    },
  ];

  return (
    <Page
      lang="en"
      title="Disposable wipes for restaurants, bars, and hotels"
      description="Neutral packaging with attention to detail, suitable for all kinds of situations and activities. We also make custom wipes to give an original touch to your venue."
      type="CollectionPage"
      readMore={["/en/contacts/#contact-form", "/en/request-free-sample"]}
      image="/disposable-wipes.jpg"
      schema={schema}
    >
      <Hero
        title="Disposable wipes"
        description={
          <>
            Neutral packaging with attention to detail, suitable for all kinds
            of situations and activities. We also make custom wipes to give an
            original touch to your venue.
          </>
        }
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h2" component="h2">
                Our selection
              </Typography>

              <Typography>
                From the warm, enveloping scents of <strong>cinnamon</strong> to
                the freshness of <strong>lemon</strong> and the oriental scent
                of myrrh.
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-limone.jpg"
                alt="Lemon wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Lemon
            </Typography>
            <Typography>Citrusy fragrance</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-lime-e-cannella.jpg"
                alt="Lime and cinnamon wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Lime and cinnamon
            </Typography>
            <Typography>Citrusy and spicy scent</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-mojito.jpg"
                alt="Mojito wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Mojito
            </Typography>
            <Typography>Fresh and fruity fragrance</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-the-verde.jpg"
                alt="Green tea wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Green tea
            </Typography>
            <Typography>Oriental scent</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-coloniale.jpg"
                alt="Colonial wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Colonial
            </Typography>
            <Typography>
              Oriental and citrusy scent
              <br /> (myrrh and bergamot)
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-lavanda-camomilla.jpg"
                alt="Lavender and chamomile wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Lavender & Chamomile
            </Typography>
            <Typography>Delicate and relaxing scent</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-gusto-sweet-fruit.jpg"
                alt="Sweet fruit wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Sweet fruit
            </Typography>
            <Typography>
              Sweet, fruity and citrusy fragrance
              <br /> (orange and apple)
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} textAlign="center">
            <Box mb={2}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/products/salvietta-monouso-personalizzata.jpg"
                alt="Customized wipe"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Customized
            </Typography>
            <Typography>Choose from many flavors</Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography variant="h2" component="h2">
                Features
              </Typography>
              <Typography>
                Our products are designed to meet several requirements. One of
                these is the <strong>removal of unpleasant odors</strong> from
                the skin, such as fish odors. At the same time, they are ideal
                for <strong>refreshing hands</strong> at the end of a meal.
              </Typography>
              <Typography>
                Due to the <strong>small size of the sachet</strong>, you can
                include it in the mise en place of your{" "}
                <strong>restaurant or venue</strong>. We can also make it
                available to customers in a convenient container.
              </Typography>
              <Typography>
                The size is designed to clean and refresh hands efficiently,
                avoiding any waste.
              </Typography>
              <Typography component="div">
                <ul>
                  <li>
                    <strong>Standard size:</strong> long side 10 cm - short side
                    6.6 cm
                  </li>
                  <li>
                    <strong>Maxi size:</strong> long side 14 cm - short side 7.3
                    cm
                  </li>
                  <li>
                    <strong>Packaging:</strong> 500 pcs, 900 pcs (150 pcs x 6
                    packs), 1,000 pcs
                  </li>
                </ul>
              </Typography>
              <Box mt={2}>
                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                  <Link
                    type="internal"
                    href="/en/contact/#contact-form"
                    title="Learn more about request a quote"
                    underline="none"
                  >
                    <Button variant="contained" color="primary" size="large">
                      Request a quote
                    </Button>
                  </Link>
                  <Link
                    type="internal"
                    href="/en/request-free-sample"
                    title="Learn more about free samples"
                    underline="none"
                  >
                    <Button variant="outlined" color="info" size="large">
                      Request a free sample
                    </Button>
                  </Link>
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <SalvaFreschezza language="en" />

      <Section
        maxWidth="md"
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
      >
        <Stack spacing={2} textAlign="center">
          <Typography variant="h3" component="h2">
            A wide choice of flavors
          </Typography>
          <Typography>
            Every venue has scents that reflect it. For example, a seafood
            restaurant surely prefers lemon or lime & cinnamon; a sunset bar by
            the sea has mojito as its ideal fragrance; a small, well-kept
            farmhouse is perfect for serving colonial (myrrh and bergamot)
            scented wipes.
          </Typography>

          <Box>
            <Link
              type="internal"
              href="/en/request-free-sample"
              title="Learn more about free sample"
              underline="none"
            >
              <Button size="large" variant="contained">
                Try our flavors
              </Button>
            </Link>
          </Box>
        </Stack>
      </Section>

      <Personalizza language="en" />
    </Page>
  );
};

export default SalviettePage;
